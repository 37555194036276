/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const SectionContainer = styled.div`
  .section-podcast-hero--wrapper {
    position: relative;
    padding: 50px 20px;
    max-width: var(--max-width);
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100px -130px;
  }

  .data-layer-form {
    justify-content: flex-start;
  }

  .hero-subscribe-form-container {
    margin-top: 30px;
  }

  .section-podcast-hero--container {
    margin: 0 auto;
    text-align: center;
    overflow: visible;
    z-index: 1;
    position: relative;

    .section-podcast-hero--main-container {
      overflow: visible;

      .section-podcast-hero-podcast-container {
        display: flex;
      }

      .section-podcast-hero--image-side {
        position: relative;
        margin: -75px auto 0 auto;

        .section-podcast-hero--image-container {
          position: relative;
          margin: 0 auto;
          display: block;

          .section-podcast-hero--image {
            width: 412px;
            box-shadow: 0px 30px 60px rgba(11, 26, 33, 0.15);
          }

          .section-podcast-hero--detail-button {
            border: none;
            border-radius: 0 !important;
            cursor: pointer;
            padding: 16px 24px;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
            background-color: var(--critical-3);
            transition: background 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
            width: 80%;
            position: relative;
            display: block;
            margin: -30px auto 0;
            box-shadow: 0px 30px 60px rgba(11, 26, 33, 0.15);

            &:hover {
              background: var(--critical-4);
            }
          }
        }

        @media (max-width: 767px) {
          margin: -75px auto 30px auto;
        }

        @media (max-width: 575px) {
          margin: -210px auto 15px auto;
        }
      }

      .section-podcast-hero--text-side {
        padding-left: 40px;
        z-index: 1;

        .section-podcast-hero--subtitle {
          color: var(--titan-blue-4);
          text-align: left;
        }

        .section-podcast-hero--title {
          text-align: left;
          margin: 16px auto 5px;
        }

        .section-podcast-hero--player {
          background: white;
          margin-top: 25px;
          box-shadow: 0px 3px 10px rgba(11, 26, 33, 0.08);

          .section-layout--inner-container {
            padding-top: 33px;
            padding-bottom: 33px;
          }
        }
      }
    }
  }

  .section-podcast-hero--favorite-container {
    margin: 90px auto 0;
    padding: 0;
    width: fit-content;

    .section-podcast-hero--subscribe-container {
      display: flex;
      justify-content: flex-start;

      .subscribe-without-padding {
        width: 520px;
        padding-right: 25px;

        @media (max-width: 991px) {
          width: 495px;
          padding: 0;
          margin-right: 45px;
        }
      }
      .section-podcast-hero--favorite-splitter {
        width: 1px;
        height: 80px;
        background: var(--grey-6);
        margin: 20px;

        @media (max-width: 991px) {
          margin: 20px 0;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }
    }
    .section-podcast-hero--favorite-title {
      text-align: left;
      color: var(--darkest);
      width: fit-content;
    }

    .section-podcast-hero--favorite-logo-container {
      max-width: 806px;
      display: flex;
      justify-content: center;
      gap: 0 50px;
      max-height: 80px;
      margin-top: 20px;
      padding-top: 6px;
      padding-left: 20px;
      z-index: 1;

      @media (max-width: 991px) {
        gap: 0 20px;
        padding-left: 25px;
      }

      .section-podcast-hero--favorite-logo {
        display: flex;
        align-items: center;
        justify-content: center;

        .section-podcast-hero--favorite-logo-img {
          width: 54px;
          height: auto;
          max-width: 100%;
        }
      }
    }

    .subscribe-without-padding {
      .section-review-hero--container {
        padding: 0;

        .section-review-hero--description {
          margin-top: 0;
        }

        .section-review-hero--subscribe-content {
          margin-top: 0px;
          padding: 0;

          .section-blog-details-legal-info {
            max-width: 500px;
            margin: 0 auto;
            text-align: center;
            line-height: var(--line-height-150);
            text-align: center;
            color: var(--darkest);
          }
        }
      }
    }
  }

  @media (min-width: 576px) and (max-width: 991px) {
    .ausha-player-tablet {
      margin-top: 10px;
    }
  }

  @media (max-width: 767px) {
    .section-podcast-hero--container {
      .section-podcast-hero--main-container {
        .section-podcast-hero-podcast-container {
          flex-direction: column;
        }
        .section-podcast-hero--image-side {
          .section-podcast-hero--image-container {
            .section-podcast-hero--image {
              width: 272px;
            }

            .section-podcast-hero--detail-button {
              font-size: var(--font-size-16);
              padding: 8px 24px;
            }
          }
        }

        .section-podcast-hero--text-side {
          .section-podcast-hero--title {
            font-size: var(--font-size-22);
            letter-spacing: var(--letter-spacing--0-02);
          }
        }
      }
    }

    .section-podcast-hero--favorite-container {
      margin: 60px auto 40px;
      width: 100%;

      .section-podcast-hero--favorite-title {
        width: 100%;
      }

      .section-podcast-hero--subscribe-container {
        > div:first-child {
          width: 100%;
        }
        .subscribe-without-padding {
          width: 100%;
        }
      }

      .section-podcast-hero--subscribe-container {
        flex-direction: column;
      }

      .section-podcast-hero--favorite-logo-container {
        max-width: 100%;
        margin: 25px auto 0;
        padding: 0;
        border: 0;

        .section-podcast-hero--favorite-logo {
          margin: 0 20px;

          .section-podcast-hero--favorite-logo-img {
            width: 50px;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .section-podcast-hero--container {
      padding-top: 120px;

      .section-podcast-hero--main-container {
        flex-direction: column;

        .section-podcast-hero--image-side {
          .section-podcast-hero--image-container {
            .section-podcast-hero--image {
              width: 100%;
            }

            .section-podcast-hero--detail-button {
            }
          }
        }

        .section-podcast-hero--text-side {
          padding-left: 0;
          padding-top: 20px;

          .section-podcast-hero--title {
            font-size: var(--font-size-22);
            letter-spacing: var(--letter-spacing--0-02);
          }
        }
      }
    }

    .section-podcast-hero--favorite-container {
      margin: 30px auto;

      .section-podcast-hero--favorite-logo-container {
        justify-content: center;
        max-width: 400px;
      }
    }
  }

  @media (max-width: 383px) {
    .section-podcast-hero--favorite-container {
      .section-podcast-hero--favorite-logo-container {
        .section-podcast-hero--favorite-logo {
          padding: 10px 5px;
        }
      }
    }
  }
`;

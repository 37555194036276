import { graphql, useStaticQuery } from 'gatsby';

const usePodcastLatestToolsEpisode = () => {
  const { allContentfulPodcast } = useStaticQuery(graphql`
    query {
      allContentfulPodcast(
        sort: { fields: [episode], order: DESC }
        limit: 1
        filter: { series: { eq: "Toolbox for the Trades" }, episode: { ne: null } }
      ) {
        edges {
          node {
            ...Podcast
          }
        }
      }
    }
  `);

  return allContentfulPodcast.edges[0].node;
};

export default usePodcastLatestToolsEpisode;

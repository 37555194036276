import React from 'react';
import useBreakpointView from 'utils/useBreakpointView';
import WebsiteImage from 'components/WebsiteImage';
import HyperLink from 'components/Core/HyperLink';
import BackgroundImage from 'components/Core/BackgroundImage';
import AushaPlayer from '../AushaPlayer';
import { SectionContainer } from './styles';
import Bookmark from 'components/Page/Toolbox/Bookmark';

const PodcastFeaturedContent = ({
  backgroundImg,
  subTitle,
  title,
  description,
  coverPhoto,
  cardPhoto,
  aushaEpisodeId,
  detailLink,
  favorites,
  series,
  ...otherProps
}) => {
  const isTablet = useBreakpointView(['sm', 'md']);

  return (
    <>
      <SectionContainer {...otherProps}>
        <div className="section-podcast-hero--wrapper">
          {backgroundImg && <BackgroundImage image={backgroundImg} loading="eager" />}
          <div className="section-podcast-hero--container">
            <div className="section-podcast-hero--main-container">
              <div className="section-podcast-hero-podcast-container">
                <div className="section-podcast-hero--image-side">
                  <Bookmark
                    type="podcast"
                    url={detailLink}
                    image={cardPhoto?.image || coverPhoto?.image}
                    title={title}
                    subTitle={subTitle}
                  >
                    <HyperLink className="section-podcast-hero--image-container" href={detailLink}>
                      <WebsiteImage
                        loading="eager"
                        className="section-podcast-hero--image"
                        image={coverPhoto?.image}
                      />
                    </HyperLink>
                  </Bookmark>
                </div>
                <div className="section-podcast-hero--text-side">
                  <p className="section-podcast-hero--subtitle font-overline">{subTitle}</p>
                  <h1 className="section-podcast-hero--title font-h2">{title}</h1>
                  {aushaEpisodeId && !isTablet && (
                    <AushaPlayer episodeId={aushaEpisodeId} series={series} />
                  )}
                </div>
              </div>
              {aushaEpisodeId && isTablet && (
                <div className="ausha-player-tablet">
                  <AushaPlayer episodeId={aushaEpisodeId} series={series} />
                </div>
              )}
            </div>
          </div>
        </div>
      </SectionContainer>
    </>
  );
};

export default PodcastFeaturedContent;

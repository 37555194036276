import styled from 'styled-components';

const Container = styled.div`
  background: var(--darkest);
  width: 100%;

  h1,
  p {
    color: var(--white);
    text-align: center;
  }

  .copy-container {
    max-width: 575px;
    margin: 0 auto;
  }

  .favorite-logo-container {
    gap: 40px;
    width: max-content;
    display: flex;
    margin: 30px auto 0 auto;

    a,
    img {
      width: 43px;
    }
  }

  padding: 250px 28px 100px 28px !important;
`;

export { Container };

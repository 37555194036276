import React from 'react';
import { getRichTextValue } from 'utils/richTextUtils';
import HyperLink from 'components/Core/HyperLink';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import { getArrayOfEntries } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';

import { Container } from './styles';

const PodcastSplashHero = (props) => {
  const entries = getArrayOfEntries(props);
  const subtitle = getRichTextValue(getEntry('subtitle', entries));
  const title = getRichTextValue(getEntry('title', entries));

  return (
    <Container className="podcast-hero">
      <div className="copy-container">
        <h1>{title}</h1>
        <p className="font-md">{subtitle}</p>
        <div className="favorite-logo-container">
          {props.favorites.map((item, index) => {
            if (item.link) {
              return (
                <HyperLink
                  className="section-podcast-hero--favorite-logo"
                  href={item.link}
                  key={index}
                >
                  <img
                    className="section-podcast-hero--favorite-logo-img"
                    src={item.logo}
                    alt={item.title}
                  />
                </HyperLink>
              );
            }
          })}
        </div>
      </div>
    </Container>
  );
};

export default PodcastSplashHero;
